






































































































































































































































































































































































.moreImg {
	width: 0.6rem;
	/* height: 0.2rem; */
	// margin-left: 5rem;
}

.sspanStyleCol22 {
	margin-left: 0.2rem;
}

.vanRowBoderStyle {
	border-bottom: 1px solid #ededed;
	margin: 0 0.3rem;
	padding: 0.15rem 0 0.2rem;
}

.bottomDivRowColStyle {
	// padding: 0.1rem;
}

.vanCol4PStyle {
	font-size: 0.28rem;
	text-align: right;
	// color: #CF4E65;
	margin-top: 0.25rem;
	span {
		font-family: Regular;
	}
}

.bottomDivRowColStylePS {
	// padding-bottom: 0.1rem;
	font-size: 0.28rem;
	line-height: 0.4rem;
	margin-top: 0.08rem;
	display: flex;
	color: #333333;
}

.bottomDivRowColStyleP2 {
	font-size: 0.24rem;
	line-height: 0.35rem;
}

.fontColorStyle {
	// color: #999;
	color: #333;
}

.zhongjiandeDiv {
	height: 0.8rem;
	font-size: 0.32rem;
	padding: 0 0.3rem;
	padding-top: 0.2rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	.head_left {
		font-size: 0.32rem;
		color: #333333;
		display: flex;
		align-items: center;
		font-weight: bold;
		.kuai {
			width: 0.1rem;
			height: 0.26rem;
			background-color: #2E62FF;
			margin-right: 0.1rem;
		}
	}
	.head_right {
		display: flex;
		align-items: center;
		.txt {
			margin-right: 0.1rem;
			color: #999999;
			font-size: 0.24rem;
		}
		.icon_arrR {
			width: 0.24rem !important;
			height: 0.24rem !important;
			border-top: 0.04rem solid #999;
			border-right: 0.04rem solid #999;
		}
	}
}



.body1Style {
	// margin: 0 0.2rem;
	background-color: #ffffff;
	padding: 0.4rem 1rem 0.4rem 1rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	.spanFlex6 {
		display: flex;
		flex-direction: column;
		align-items: center;
		.img {
			width: 0.6rem;
			height: 0.6rem;
			img {
				width: 100%;
				height: 100%;
			}
		}
		.txt {
			margin-top: 0.03rem;
			text-align: center;
			font-family: PingFang SC;
			font-weight: 500;
			font-size: 0.28rem;
			color: #333333;
		}
		// &.sbtn1 {
		//  background-image: url('../../assets/imgs/new_color/money_bg.png');
		// }
		// &.sbtn2 {
		// 	  background-image: url('../../assets/imgs/wallet/icon_withdrawmoney.png');
		// }
		// &.sbtn3 {
		// 	  background-image: url('../../assets/imgs/wallet/icon_transferaccounts.png');
		// }
	}
}
.new_bgMask {
	background-color: #ffffff;
	.bgMask {
		margin-top: -0.5rem;
		height: 0.69rem;
		width: 100%;
		background: url(../../assets/imgs/new_color/mask.png) no-repeat center top;
		background-size: 100% 0.69rem;
		position: relative;
		z-index: 100;
	}
}
.whole {
	/* background: #ffffff; */
	font-family: PingFangSC-Regular;
}
.content {
	position: relative;
	// top: 0.88rem;
	padding-top: calc(env(safe-area-inset-top) + 1.08rem);
	left: 0;
	width: 100%;
	// z-index: 9999;
	&::before {
		content: '';
		height: calc(env(safe-area-inset-top) + 3.95rem);
		width: 100%;
		background: url(../../assets/imgs/new_color/qianbao_bg.png) no-repeat center top;
		background-size: 100% 100%;
		display: inline-block;
		position: absolute;
		top: 0;
	}
}
.accountInfo {
	color: white;
	font-size: 0.24rem;
	margin: 0 0.4rem;
	height: 2.82rem;
	border-radius: 0.12rem;
	// margin-top: -2.45rem;
	position: relative;
	z-index: 10;
	// background-image: url(../../assets/imgs/new_color/q_bg.png);
	// background-size: 100% 100%;
	// background-repeat: no-repeat;
	z-index: 99;
	.head_txt {
		padding: 0.4rem 0.4rem 0.8rem 0.4rem;
		color: #FFFFFF;
		font-size: 0.4rem;
		font-family: pingfangSC-Medium;
	}
	.qianbao {
		display: flex;
		align-items: center;
		justify-content: space-around;
		.item {
			width: 33.3%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			text-align: center;
			&:nth-child(2) {
				// margin-top: 0;
				border-right: 0.02rem solid rgba(250, 205, 182, 0.33);
			}
			&:nth-child(2) {
				// margin-top: 0;
				border-left: 0.02rem solid rgba(250, 205, 182, 0.33);
			}
			.title {
				color: #FFFFFF;
				font-size: 0.24rem;
				font-family: PingFang SC;
			}
			.yen {
				color: #FFFFFF;
				font-size: 0.28rem;
				font-family: PingFang SC;
			}
		}
	}
}
.leixin {
	width: 2.3rem;
}
.mainColor1 {
	color: #32bf88 !important;
}
.mainColor3 {
	color: #f5465d !important;
}
.container {
	margin-top: 0.2rem;
	border-top-left-radius: 0.32rem;
	border-top-right-radius: 0.32rem;
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;