

















































































































































































































































































































































































































































































































































































































































.commonRouter {
	padding: 0.4rem 0.4rem 0.3rem 0.4rem;
	background-color: #FFFFFF;
	display: flex;
	justify-content: space-between;
	li {
		color: #5569FC;
		font-size: .32rem;
		width: 3.2rem;
		height:0.68rem;
		border: 1px solid #5569FC;
		border-radius: 0.16rem;
		font-weight: bold;
		// border: 2px solid #FFFFFF;
		// border-radius: .36rem;
		display: flex;
		justify-content: center;
		align-items: center;
		&.active {
			color: #FFFFFF;
			background: #5569FC;
			border: none;
		}
	}
}

.layoutBox {
	padding: 0 .2rem;
	min-height: calc(100vh - 2.1rem);
	position: relative;
}

.commonTabtit {
	position: relative;
	padding: 0 0.4rem;
	color: #6F7A8B;
	background-color: #FFFFFF;
	font-size: .28rem;
	height: 0.66rem;
	display: flex;
	justify-content: space-between;
	// justify-content: flex-end;
	align-items: center;
	margin-bottom: .2rem;
	border-bottom: 0.05rem solid #FFFFFF;
	.type {
		display: flex;
		align-items: center;
		li {
			line-height: 0.66rem;
			margin-right: .5rem;
			&.active {
				color: #333333;
				font-weight: bold;
				border-bottom: 0.05rem solid #5569FC;
			}
		}
	}
	.img {
		img {
			width: 0.38rem;
			height: 0.36rem;
		}
	}
	.exchange {
		height: .8rem;
		display: flex;
		align-items: center;
		text-transform: uppercase;
		.icon_arr_up {
			width: .1rem;
			height: .1rem;
			border-right: 2px solid #666666;
			border-bottom: 2px solid #666666;
			-webkit-transform: rotate(45deg);
			transform: rotate(45deg);
			margin-left: .15rem;
			display: inline-block;
		}
	}
	.tan {
		z-index: 99;
		position: absolute;
		right: 0;
		top: 0.59rem;
		background-color: #FFFFFF;
		width: 100%;
		height: 6.4rem;
		.content {
			margin: 0.3rem 0.4rem;
			.head_1 {
				color: #333333;
				font-size: 0.28rem;
				margin-bottom: 0.16rem;
			}
			.exe_list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 0.32rem;
				.item {
					font-weight: bold;
					padding: 0.06rem 0.18rem;
					background-color: #F9FAFB;
					color: #999999;
					text-align: center;
					border-radius: 0.04rem;
				}
				.active {
					background-color: #E7ECEF;
					color: #333333;
					position: relative;
				}
				// .active:before {
				//         content: '';
				//         position: absolute;
				//         right: 0;
				//         bottom: 0;
				//         border: 0.12rem solid #1677FF;
				//         border-top-color: transparent;
				//         border-left-color: transparent;
				// }
				// .active:after {
				//     content: '';
				// 	width: 1px;
				// 	height: 3px;
				// 	position: absolute;
				// 	right: 1px;
				// 	bottom: 1px;
				// 	border: 2px solid #fff;
				// 	border-top-color: transparent;
				// 	border-left-color: transparent;
				// 	transform: rotate(45deg);
				// }
			}
			.exe_list1 {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				margin-bottom: 0.32rem;
				.item {
					font-weight: bold;
					padding: 0.06rem 0.34rem;
					background-color: #F9FAFB;
					color: #999999;
					text-align: center;
					margin-left: 0.5rem;
					border-radius: 0.04rem;
					&:first-child {
						margin-left: 0;
					}
				}
				.active {
					background-color: #E7ECEF;
					color: #333333;
					position: relative;
				}
				// .active:before {
				//         content: '';
				//         position: absolute;
				//         right: 0;
				//         bottom: 0;
				//         border: 0.12rem solid #1677FF;
				//         border-top-color: transparent;
				//         border-left-color: transparent;
				// }
				// .active:after {
				//     content: '';
				// 	width: 1px;
				// 	height: 3px;
				// 	position: absolute;
				// 	right: 1px;
				// 	bottom: 1px;
				// 	border: 2px solid #fff;
				// 	border-top-color: transparent;
				// 	border-left-color: transparent;
				// 	transform: rotate(45deg);
				// }
			}
			.head_2 {
				color: #373737;
				font-size: 0.32rem;
				margin-bottom: 0.38rem;
			}
			.dui_list {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin-bottom: 0.48rem;
				.input {
					// border-bottom: 0.02rem solid #2568E4;
					input {
						border: none;
						text-align: center;
						font-size: 0.24rem;
						transform: scale(0.91);
						color: #999999;
						padding: 0.2rem 0;
						background-color: #F9FAFB;
						padding: 0.19rem 0.255rem;
						margin-left: -0.12rem;
						border-radius: 0.08rem;
					}
				}
				.fenge {
					width: 0.04rem;
					height: 0.51rem;
					background-color: #5569FC;
				}
			}
			.button {
				display: flex;
				justify-content: space-between;
				.button_1 {
					background-color: #E7ECEF;
					color: #333333;
					font-size: .32rem;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 3.2rem;
					height: 0.96rem;
					border-radius: 0.16rem;
					// background-repeat: no-repeat;
					// background-position: center center;
					// background-size: 100% 100%;
					// background-image: url(../..//assets/imgs/bag-w.png);
				}
				.button_2 {
					background-color: #5569FC;
					color: #FFFFFF;
					font-size: .32rem;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 3.2rem;
					height: 0.96rem;
					border-radius: 0.16rem;
					// background-repeat: no-repeat;
					// background-position: center center;
					// background-size: 100% 100%;
					// background-image: url(../..//assets/imgs/bag-b.png);
				}
			}
		}
	}
}

// .bill-list-item {
// 	color: #666666;
// 	font-size: .28rem;
// 	background: #F5F5F5;
// 	border-radius: .12rem;
// 	margin-bottom: 0.25rem;
// 	.hd {
// 		padding: .25rem .2rem;
// 		.caption {
// 			height: .4rem;
// 			display: flex;
// 			justify-content: space-between;
// 			align-items: center;
// 			margin-bottom: .25rem;
// 			.yen {
// 				color: #373737;
// 				margin-right: .25rem;
// 			}
// 			.type {
// 				font-size: .24rem;
// 			}
// 		}
// 		.specialFont {
// 			color: #7675D8 !important;
// 			text-transform: uppercase;
// 		}
// 		.info {
// 			display: flex;
// 			justify-content: space-between;
// 			align-items: center;
// 			height: .4rem;
// 			.date {
// 				color: #373737;
// 				font-size: .24rem;
// 			}
// 		}
// 	}
// 	.bd {
// 		color: #373737;
// 		padding: .5rem .2rem  0;
// 		li {
// 			margin-bottom: .25rem;
// 		}
// 		span {
// 			color: #666666;
// 			width: 1.65rem;
// 			display: inline-block;
// 		}
// 	}
// 	.fd {
// 		height: .5rem;
// 		position: relative;
// 		display: flex;
// 		justify-content: center;
// 		align-items: center;
// 		&::before {
// 			content: '';
// 			position: absolute;
// 			z-index: 1;
// 			left: 0;
// 			right: 0;
// 			top: 50%;
// 			border-top: 1px solid #D8D8D8;
// 		}
// 		img {
// 			width: .24rem;
// 			height: .24rem;
// 			display: block;
// 			background: #F5F5F5;
// 			position: relative;
// 			z-index: 2;
// 			&.up {
// 				-webkit-transform: rotate(180deg);
// 				transform: rotate(180deg);
// 			}
// 		}
// 	}
	
// }
.van-list {
	z-index: -99 !important;
}
.zhezhao {
	// height: 100vh;
	top: calc(env(safe-area-inset-top) + 22vh);
}

@font-size-sm: 14px;@font-size-md: 16px;@font-size-lg: 18px;@goods-action-button-danger-color: #7232dd;@goods-action-button-warning-color: #3eaf7c;